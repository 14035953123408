<template>
  <div class="d-flex">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col cols="4" class="pr-3">
          <v-select
            label="Parameter"
            v-model="rule.id"
            :items="options"
            item-text="text"
            item-value="index"
            dense
            outlined
          />
        </b-col>
        <b-col cols="8" v-if="options">
          <b-row
            class="pr-3"
            v-if="
            options.find((option) => option.index == rule.id).field_type ==
              'text' ||
            options.find((option) => option.index == rule.id).field_type ==
              'select'
          "
          >
            <b-col cols="6" class="pr-3">
              <v-text-field
                v-model="rule.from"
                label="from"
                dense
                outlined
                :type="formatType == 1 ? 'number' : 'string'"
                :min="formatType == 1 ? 0 : ''"
                :max="formatType == 1 ? inputLength - 1 : ''"
                :error-messages="fromErrors"
                @blur="$v.rule.from.$touch()"
              />
            </b-col>
            <!--        {{ rule.value[0] }}{{ inputLength }}{{ rule.value[0] > inputLength }}-->
            <b-col cols="6">
              <v-text-field
                v-model="rule.to"
                label="to"
                dense
                outlined
                :type="formatType == 1 ? 'number' : 'string'"
                :min="formatType == 1 ? 1 : ''"
                :max="formatType == 1 ? inputLength : ''"
                :error-messages="toErrors"
                @blur="$v.rule.to.$touch()"
              />
            </b-col>
          </b-row>
          <b-row
            class="pr-3"
            v-if="
            options.find((option) => option.index == rule.id).field_type ==
            'date'
          "
          >
            <b-col cols="3" class="pr-3">
              <v-text-field
                v-model="rule.from"
                label="from"
                dense
                outlined
                :type="formatType == 1 ? 'number' : 'string'"
                :min="formatType == 1 ? 0 : ''"
                :max="formatType == 1 ? inputLength - 1 : ''"
                :error-messages="fromErrors"
                @blur="$v.rule.from.$touch()"
              />
            </b-col>
            <!--        {{ rule.value[0] }}{{ inputLength }}{{ rule.value[0] > inputLength }}-->
            <b-col cols="3">
              <v-text-field
                v-model="rule.to"
                label="to"
                dense
                outlined
                :type="formatType == 1 ? 'number' : 'string'"
                :min="formatType == 1 ? 1 : ''"
                :max="formatType == 1 ? inputLength : ''"
                :error-messages="toErrors"
                @blur="$v.rule.to.$touch()"
              />
            </b-col>
            <b-col cols="6">
              <v-select
                v-model="rule.format"
                label="format"
                :items="options.find((option) => option.index == rule.id).formats"
                item-text="text"
                item-value="index"
                dense
                outlined
                :error-messages="formatErrors"
                @blur="$v.rule.format.$touch()"
              />
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-container>
    <div class="max-h-65">
      <button
        class="h-40px w-40px btn btn-sm btn-icon btn-light-info btn-hover-light"
        @click="deleteRule"
      >
        <span class="svg-icon">
          <v-icon size="18">mdi-delete</v-icon>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { SET_ACTION_NOT_VALID } from "@/core/services/store/allocationStrategyRules.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "Action",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    rule: {
      from: { required },
      to: { required },
      format: {
        required: requiredIf(function () {
          return (
            this?.options?.find((option) => option.index == this.rule.id)
              .field_type == "date"
          );
        }),
      },
    },
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
    },
    formatType: {
      type: Number,
      required: true,
    },
    inputLength: {
      required: true,
    },
  },
  computed: {
    fromErrors: function () {
      return this.handleFormValidation("from", this, "rule");
    },
    toErrors: function () {
      return this.handleFormValidation("to", this, "rule");
    },
    formatErrors: function () {
      return this.handleFormValidation("format", this, "rule");
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },

    deleteRule: function () {
      const self = this;
      const parentRules = self.$parent.currentQuery;
      parentRules.splice(parentRules.indexOf(self.rule), 1);
    },
  },
  watch: {
    currentRuleID: function () {
      this.rule.value = null;
    },
    rule: {
      handler(val) {
        this.$v.$touch();
        if (this.formatType == 1) {
          if (this.inputLength) {
            if (val.from) {
              if (parseInt(val.from) > parseInt(this.inputLength)) {
                this.$nextTick(() => {
                  this.rule.from = this.inputLength - 1;
                  this.$forceUpdate();
                });
              } else if (val.from < 0) {
                this.$nextTick(() => {
                  this.rule.from = 0;
                  this.$forceUpdate();
                });
              }
            }
            if (val.to) {
              if (parseInt(val.to) > parseInt(this.inputLength)) {
                this.$nextTick(() => {
                  this.rule.to = this.inputLength;
                  this.$forceUpdate();
                });
              } else if (val.to < 0) {
                this.$nextTick(() => {
                  this.rule.to = 0;
                  this.$forceUpdate();
                });
              }
            }
          }
        }

        if (this.$v.$invalid) {
          this.$store.commit(SET_ACTION_NOT_VALID, true);
        } else {
          this.$store.commit(SET_ACTION_NOT_VALID, false);
        }
      },
      deep: true,
    },
  },
};
</script>
